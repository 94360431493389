/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "form-item__label-content"
};
var _hoisted_2 = {
  class: "form-item__main"
};
var _hoisted_3 = {
  key: 0,
  class: "form-item__msg"
};
var _hoisted_4 = {
  key: 1,
  class: "form-item__err-msg"
};
export default {
  __name: 'FormItem',
  props: {
    horizontal: Boolean,
    horizontalLabelMaxPdt: Number,
    label: String,
    labelWidth: String,
    // 樣式
    variant: String,
    required: Boolean,
    requiredString: String
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;
    var cData = reactive({
      labelPdt: 0
    });
    var refRoot = ref(null);
    var refItemLabel = ref(null);
    var refItemContent = ref(null);

    function checkLabelPdt() {
      if (!refItemLabel.value) return;
      var labelComputedStyle = getComputedStyle(refItemLabel.value);
      var Label1remH = parseFloat(labelComputedStyle.lineHeight) || 0;
      var contentH = refItemContent.value.clientHeight;
      var pdt = contentH / 2 - Label1remH / 2;

      if (props.horizontalLabelMaxPdt) {
        if (pdt >= props.horizontalLabelMaxPdt) {
          cData.labelPdt = props.horizontalLabelMaxPdt;
        }
      } else {
        cData.labelPdt = pdt;
      }
    }

    function update() {
      checkLabelPdt();
    }

    onMounted(function () {
      checkLabelPdt(); // 監聽更新要求

      refRoot.value.addEventListener('toUpdate', function () {
        update();
      });
    });
    expose({
      // 提供外部元件來更新顯示
      update: update
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: _normalizeClass(["form-item", [{
          'form-item_horizontal': __props.horizontal
        }, __props.variant ? "form-item_".concat(__props.variant) : null]]),
        style: _normalizeStyle({
          '--form-item-label-pdt': "".concat(_unref(cData).labelPdt, "px"),
          '--form-item-label-width': __props.labelWidth || null
        })
      }, [_ctx.$slots['label'] || __props.label ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "refItemLabel",
        ref: refItemLabel,
        class: "form-item__label"
      }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "label", {}, function () {
        return [_createTextVNode(_toDisplayString(__props.label), 1)];
      }), __props.required ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["form-item__required", {
          'form-item__required--string': __props.requiredString
        }])
      }, _toDisplayString(__props.requiredString || '*'), 3)) : _createCommentVNode("", true)])], 512)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        ref_key: "refItemContent",
        ref: refItemContent,
        class: "form-item__content"
      }, [_renderSlot(_ctx.$slots, "default")], 512), _ctx.$slots['msg'] ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_renderSlot(_ctx.$slots, "msg")])) : _createCommentVNode("", true), _ctx.$slots['err-msg'] ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "err-msg")])) : _createCommentVNode("", true)])], 6);
    };
  }
};