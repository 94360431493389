import { createRouter, createWebHistory } from "vue-router"
import { resolveDynamicComponent } from 'vue'
import { layout } from '@/plugin/layout'
import store from "@/store/index"
import storage from '@/js/storage'
import Index from "@/pages/Index.vue"
import Swal from "sweetalert2"

const routes = [
  {
    path: "/",
    alias: "/website",
    name: "/",
    component: Index,
  },
  {
    path: "/login",
    alias: "/website/login",
    name: "",
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: "/calendar",
    alias: "/website/calendar",
    name: "",
    redirect: {
      path: '/calendar/time-grid-personnel'
    },
    component: () => import('@/pages/Calendar.vue'),
    children: [
      {
        path: "time-grid-personnel",
        alias: "time-grid-personnel",
        // name: vuex用來識別當前日曆
        name: "timeGridPersonnel",
        component: () => import('@/components/MJCalendarTimegridPersonnel.vue'),
        beforeEnter: (to, from) => {
          if (store.getters['auth/isCome']) {
            return '/calendar/time-grid-room'
          }
          store.dispatch('calendar/getEvents', {
            calendarType: 'day'
          })
        },
      },
      {
        path: "time-grid-room",
        alias: "time-grid-room",
        // name: vuex用來識別當前日曆
        name: "timeGridRoom",
        component: () => import('@/components/MJCalendarTimegridRoom.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('calendar/getEvents', {
            calendarType: 'day'
          })
        },
      },
      {
        path: "time-grid-day",
        alias: "time-grid-day",
        // name: vuex用來識別當前日曆
        name: "timeGridDay",
        component: () => import('@/components/MJCalendarTimegridDay.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('calendar/getEvents', {
            calendarType: 'day'
          })
        },
      },
      {
        path: "time-grid-week",
        alias: "time-grid-week",
        // name: vuex用來識別當前日曆
        name: "timeGridWeek",
        component: () => import('@/components/MJCalendarTimegridWeek.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('calendar/getEvents', {
            calendarType: 'week'
          })
        },
      },
      {
        path: "day-grid-month",
        alias: "day-grid-month",
        // name: vuex用來識別當前日曆
        name: "dayGridMonth",
        component: () => import('@/components/MJCalendarDaygrid.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('calendar/getEvents', {
            calendarType: 'month'
          })
        },
      },
      {
        path: "custom-list",
        alias: "custom-list",
        // name: vuex用來識別當前日曆
        name: "customList",
        component: () => import('@/components/MJCalendarCustomList.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('calendar/getEvents', {
            calendarType: 'day'
          })
        },
      },
    ]
  },
  {
    path: "/work-detail",
    alias: "/website/work-detail",
    name: "",
    component: () => import('@/pages/WorkDetail.vue'),
  },
  {
    path: "/work-detail-step1",
    alias: "/website/work-detail-step1",
    name: "",
    component: () => import('@/pages/WorkDetailStep1.vue'),
  },
  {
    path: "/work-detail-step2",
    alias: "/website/work-detail-step2",
    name: "",
    component: () => import('@/pages/WorkDetailStep2.vue'),
  },
  {
    path: "/consumable",
    alias: "/website/consumable",
    name: "",
    component: () => import('@/pages/Consumable.vue'),
  },
  {
    path: "/doctor-records",
    alias: "/website/doctor-records",
    name: "",
    component: () => import('@/pages/DoctorRecords.vue'),
  },
  {
    path: "/customer-records",
    alias: "/website/customer-records",
    name: "",
    component: () => import('@/pages/CustomerRecords.vue'),
  },
  {
    path: "/announcement",
    alias: "/website/announcement",
    name: "announcement",
    component: () => import('@/pages/Announcement.vue'),
  },
  {
    path: "/announcement-detail",
    alias: "/website/announcement-detail",
    name: "announcement-detail",
    component: () => import('@/pages/AnnouncementDetail.vue'),
  },
  {
    path: "/add-announcement",
    alias: "/website/add-announcement",
    name: "",
    component: () => import('@/pages/AddAnnouncement.vue'),
  },
  {
    path: "/customer-management",
    alias: "/website/customer-management",
    name: "",
    component: () => import('@/pages/CustomerManagement.vue'),
  },
  {
    path: "/dashboard",
    alias: "/website/dashboard",
    name: "",
    component: () => import('@/pages/DashBoard.vue'),
  },
  {
    path: "/customer",
    alias: "/website/customer",
    name: "",
    redirect: {
      path: '/customer/book-record'
    },
    component: () => import('@/pages/customer/Customer.vue'),
    children: [
      {
        path: "book-record",
        alias: "book-record",
        name: "",
        component: () => import('@/pages/customer/BookRecord.vue'),
      },
      {
        path: "order-record",
        alias: "order-record",
        name: "",
        component: () => import('@/pages/customer/OrderRecord.vue'),
      },
      {
        path: "course-record",
        alias: "course-record",
        name: "",
        component: () => import('@/pages/customer/CourseRecord.vue'),
      },
      {
        path: "course-left",
        alias: "course-left",
        name: "",
        component: () => import('@/pages/customer/CourseLeft.vue'),
      },
      {
        path: "consent",
        alias: "consent",
        name: "",
        component: () => import('@/pages/customer/Consent.vue'),
      },
      {
        path: "doctor-operate-record",
        alias: "doctor-operate-record",
        name: "",
        component: () => import('@/pages/customer/DoctorOperateRecord.vue'),
      },
      {
        path: "customer-info-record",
        alias: "customer-info-record",
        name: "",
        component: () => import('@/pages/customer/CustomerInfoRecord.vue'),
      },
      {
        path: "ba",
        alias: "ba",
        name: "",
        component: () => import('@/pages/customer/BA.vue'),
      },
      {
        path: "data-card",
        alias: "data-card",
        name: "",
        component: () => import('@/components/DataCard.vue'),
        redirect: {
          path: '/customer/data-card/basic-info'
        },
        children: [
          // // 美加
          // {
          //     path: "mj/basic-info",
          //     alias: "mj/basic-info",
          //     name: "DataCardBasicInfo",
          //     component: () => import('@/components/DataCardBasicInfo.vue'),
          // },
          // {
          //     path: "kb/basic-info",
          //     alias: "kb/basic-info",
          //     name: "DataCardBasicInfo",
          //     component: () => import('@/components/DataCardBasicInfo.vue'),
          // },
          // // 康博
          // {
          //     path: "mj/questionnaire",
          //     alias: "mj/questionnaire",
          //     name: "DataCardBasicInfo",
          //     component: () => import('@/components/DataCardQuestionnaire.vue'),
          // },
          // {
          //     path: "kb/questionnaire",
          //     alias: "kb/questionnaire",
          //     name: "DataCardBasicInfo",
          //     component: () => import('@/components/DataCardQuestionnaire.vue'),
          // },

          {
            path: "basic-info",
            alias: "basic-info",
            name: "DataCardBasicInfo",
            component: () => import('@/components/DataCardBasicInfo.vue'),
          },
          {
            path: "questionnaire",
            alias: "questionnaire",
            name: "DataCardQuestionnaire",
            component: () => import('@/components/DataCardQuestionnaire.vue'),
          },
        ]
      },
    ]
  },
  // 提供後台操作用客戶資料卡
  // 後台使用iframe載入本頁面
  {
    path: "/data-card",
    alias: "/website/data-card",
    name: "",
    component: () => import('@/components/DataCard.vue'),
    redirect: {
      path: '/data-card/basic-info'
    },
    meta: { requiresAuth: false },
    children: [
      {
        path: "basic-info",
        alias: "basic-info",
        name: "DataCardBasicInfo2",
        component: () => import('@/components/DataCardBasicInfo.vue'),
        props: { 
          guest: true
        }
      },
      {
        path: "questionnaire",
        alias: "questionnaire",
        name: "DataCardQuestionnaire2",
        component: () => import('@/components/DataCardQuestionnaire.vue'),
      },
    ]
  },
  {
    path: "/mobile-data-card",
    alias: "/website/mobile-data-card",
    name: "",
    component: () => import('@/pages/MobileDataCard.vue'),
  },
  {
    path: "/all-tags",
    alias: "/website/all-tags",
    name: "",
    component: () => import('@/pages/AllTags.vue'),
  },
  {
    path: "/calendar-search",
    alias: "/website/calendar-search",
    name: "",
    component: () => import('@/pages/CalendarSearch.vue'),
  },
  {
    path: "/calendar-search-sticky",
    alias: "/website/calendar-search-sticky",
    name: "",
    component: () => import('@/pages/CalendarSearchSticky.vue'),
  },
  {
    path: "/add-customer-record",
    alias: "/website/add-customer-record",
    name: "",
    component: () => import('@/pages/AddCustomerRecord.vue'),
  },
  {
    path: "/consult-sheet",
    alias: "/website/consult-sheet",
    name: "",
    component: () => import('@/pages/ConsultSheet.vue'),
  },
  {
    path: "/care-list",
    alias: "/website/care-list",
    name: "",
    component: () => import('@/pages/CareList.vue'),
  },
  // print 列印
  {
    path: "/service-preview-sheet",
    alias: "/website/service-preview-sheet",
    name: "",
    component: () => import('@/pages/print/ServicePreviewSheet.vue'),
  },
  {
    path: "/customer-contact-record",
    alias: "/website/customer-contact-record",
    name: "",
    component: () => import('@/pages/print/CustomerContactRecord.vue'),
  },
  // 簡訊
  {
    path: "/msg",
    alias: "/website/msg",
    name: "",
    component: () => import('@/pages/msg/Index.vue'),
  },
  {
    path: "/website/note",
    alias: "/note",
    name: "",
    component: () => import('@/pages/Note.vue'),
  },
  {
    path: "/website/lock",
    alias: "/lock",
    name: "",
    component: () => import('@/pages/Lock.vue'),
  },
  // 現場房況
  {
    path: "/website/room",
    alias: "/room",
    name: "",
    component: () => import('@/pages/Room.vue'),
  },
  {
    path: "/website/care",
    alias: "/care",
    name: "",
    component: () => import('@/pages/Care.vue'),
  },
  {
    path: "/website/orphan",
    alias: "/orphan",
    name: "",
    component: () => import('@/pages/Orphan.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})


router.beforeEach(async (to, from) => {
  /**
   * - 沒有 return 視為通過
   * - 有 return 則轉至該頁
   */
  const { name, path } = to
  const isLogined = store.state.auth.isLogined

  if (isLogined) {

    if(store.state.auth.allPermission) {
      // 正常權限
      store.dispatch('updateAnnouncements')
    } else {
      // 沒權限的能在預約表
      console.log(path);
      if(!(path.includes('/calendar') || path.includes('/announcement'))) {
        return '/calendar'
      } 
    }

    if (path !== '/login' && to.matched.length) {
    } else {
      return '/'
    }
  } else {
    const noNeedLoginRoute = ["/login", "/mobile-data-card", "/msg"]
    if(to.meta.requiresAuth === false) {
      return
    }
    // 如果進去的是要登入的
    if (!noNeedLoginRoute.includes(path)) {
      return '/login'
    }
  }
})


/**
 * 仿Nuxt layout切換
 * https://mini-ghost.dev/blog/dynamic-vue-layout/
 */
router.afterEach((to, from) => {

  // 處理Layout
  let layoutProperty = to.matched[0].components.default.layout

  if (typeof layoutProperty === 'function') {
    layoutProperty = layoutProperty()
  }

  layout.value = layoutProperty || 'Default'


  // 在這些頁面內要保留tempSelectedEmployee 
  if(Object.keys(store.state.tempSelected).length) {
    let notClearTempSelectedArr = ['/','/consult-sheet','/work-detail-step1','/work-detail-step2', '/doctor-records', '/customer-records']
    if(notClearTempSelectedArr.includes(to.path)) return
    store.commit('setData', {
      tempSelected: {}
    })
  }
  if(Object.keys(store.state.tempOrphanSelected).length) {
    let notClearTempOrphanSelectedArr = ['/orphan', '/customer/data-card/basic-info']
    if(notClearTempOrphanSelectedArr.includes(to.path)) return
    store.commit('setData', {
      tempOrphanSelected: {}
    })
  }

  // 處理延遲訊息
  var delayedAction = store.state.delayedAction

  if (delayedAction && delayedAction.data) {
    var data = delayedAction.data
    var msg = data.msg

    if (msg) {

      appSwalCustomDialog.open({
        title: msg
      })

      store.commit('setData', {
        delayedAction: {}
      })
    }
  }
})

export default router
