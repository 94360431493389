/* unplugin-vue-components disabled */import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "inline-calendar"
};
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
/**
 * 使用效能較佳的flatpicker取代的v-calendar
 */
// import { Calendar, DatePicker } from 'v-calendar';
// export default {
//     components: {
//         DatePicker
//     },
// }

export default {
  __name: 'InlineCalendar',
  props: {
    /**
     * 選擇的日期
     * @property {String|Object} modelValue - Date可解析的日期格式()
     */
    modelValue: {
      type: [String, Object]
    },
    minDate: {
      type: String,
      default: null
    }
  },
  emits: ['init', 'update:modelValue', 'update:page'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;

    var MandarinTraditional = require("flatpickr/dist/l10n/zh-tw.js").default.zh_tw;

    MandarinTraditional.weekdays.shorthand = MandarinTraditional.weekdays.shorthand.map(function (str) {
      return str.replace('週', '');
    });
    var flatPicker = '';
    var refDatePicker = ref(null);
    /**
     * 如果沒有modelValue就給今日作為預設值
     * 但不emit，這麼作能讓日曆的view是在當前月份
     * 
     * 不使用jumptoDate的原因是: 日曆在hiiden跟visible切換的過程view會跑掉
     */

    var value = ref(props.modelValue || new Date());

    function onUpdate(selectedDates, dateStr, instance) {
      emit('update:page', {
        year: instance.currentYear,
        month: instance.currentMonth + 1
      });
    }

    function onDayClick(evt) {}

    watch(function () {
      return props.modelValue;
    }, function () {
      var mm = moment(props.modelValue);

      if (mm.isValid()) {
        value.value = props.modelValue;
      }
    });
    watch(function () {
      return value.value;
    }, function () {
      emit('update:modelValue', value.value);
    });

    function onflatPickrReady(flatPickerInstance) {
      flatPicker = flatPickerInstance;
      emit('init', {
        vallina: flatPickerInstance
      });
    }

    onMounted(function () {
      if (value.value) {
        var initDate = new Date(value.value);
        emit('update:page', {
          year: initDate.getFullYear(),
          month: initDate.getMonth() + 1
        });
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(flatPickr), {
        ref_key: "refDatePicker",
        ref: refDatePicker,
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(value) ? value.value = $event : null;
        }),
        config: {
          minDate: __props.minDate,
          inline: true,
          formatDate: function formatDate(date) {
            // 回傳ISO string
            return date.toISOString();
          },
          locale: _unref(MandarinTraditional),
          onReady: function onReady() {
            var flatPickerInstance = this;
            onflatPickrReady(flatPickerInstance);
          }
        },
        onOnMonthChange: onUpdate,
        onOnYearChange: onUpdate
      }, null, 8, ["modelValue", "config"])]);
    };
  }
};